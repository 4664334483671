.contact {
  flex-direction: column;
}

.contacts {
  display: flex;
}

.contact a {
  margin: 0.5rem;
}